<div class="mobile_sidemenu" (click)="mobile_expand()">
    <button><i class="fa fa-bars"></i></button>
</div>
<div class="sidebar_block" kendoTooltip position="right">
    <div class="expand_collapse_block"><button title="Collapse / Expand" (click)="expand_collapse()"><i
                class="fa fa-angle-left"></i></button></div>
    <button (click)="close_mobile_menu()" class="close_btn close"><span>×</span></button>
    <div class="product_block">
        <ul>
            <!-- <li *ngIf="globals.ProductPermission.covid"
                [ngClass]="{'active': globals.ProductId==globals.ProductIdArray[1]}"><button title="COVID"
                    (click)="changeProduct(globals.ProductIdArray[1], 'covid')"><img
                        src="{{globals.cndURL}}assets/images/covid.png" alt="" class="img-fluid" /></button></li>
            <li *ngIf="globals.ProductPermission.dcm && (globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)"
                [ngClass]="{'active': globals.ProductId==globals.ProductIdArray[2]}"><button title="DCM"
                    (click)="changeProduct(globals.ProductIdArray[2], 'dcm/admin-dashboard')"><img
                        src="{{globals.cndURL}}assets/images/dcm.png" alt="" class="img-fluid" /></button></li>
            <li *ngIf="globals.ProductPermission.dcm && !(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)"
                [ngClass]="{'active': globals.ProductId==globals.ProductIdArray[2]}"><button title="DCM"
                    (click)="changeProduct(globals.ProductIdArray[2], 'dcm/organization-dashboard')"><img
                        src="{{globals.cndURL}}assets/images/dcm.png" alt="" class="img-fluid" /></button></li> -->
            <!-- <li><button title="Settings"><img src="{{globals.cndURL}}assets/images/settings-menu.png" alt=""
                        class="img-fluid" /></button></li> -->
        </ul>
        <div class="clearfix"></div>
    </div>

    <!-- <div class="menu_block" *ngIf="globals.ProductId==globals.ProductIdArray[0]">
        <ul>
            <li>
                <div class="title_menu">Dashbaord</div>
            </li>
            <li><a href=""><i class="fa fa-tasks" title="My Assessments"></i><span>My Assessments</span></a></li>
            <li><a href=""><i class="fa fa-tachometer" title="Admin Dashboard"></i><span>Admin Dashboard</span></a></li>
        </ul>
        <ul>
            <li>
                <div class="title_menu">Manage</div>
            </li>
            <li>
                <div class="title_small">General</div>
            </li>
            <li class="active"><a href=""><i class="fa fa-flag" title="Counties"></i><span>Counties</span></a></li>
            <li><a href=""><i class="fa fa-map-marker" title="States"></i><span>States</span></a></li>
            <li><a href=""><i class="fa fa-key" title="Role Permission"></i><span>Role Permission</span></a></li>
            <li>
                <div class="title_small">License</div>
            </li>
            <li><a href=""><i class="fa fa-user-plus" title="Assign Licenses"></i><span>Assign Licenses</span></a></li>
            <li><a href=""><i class="fa fa-list" title="License Packs"></i><span>License Packs</span></a></li>
            <li>
                <div class="title_small">Assessment</div>
            </li>
            <li><a href=""><i class="fa fa-th" title="Domains"></i><span>Domains</span></a></li>
            <li><a href=""><i class="fa fa-plus" title="Extension Requests"></i><span>Extension Requests</span></a></li>
            <li><a href=""><i class="fa fa-list-ol" title="Items"></i><span>Items</span></a></li>
            <li><a href=""><i class="fa fa-sitemap" title="Levels"></i><span>Levels</span></a></li>
            <li><a href=""><i class="fa fa-list" title="List"></i><span>List</span></a></li>
        </ul>
        <ul>
            <li>
                <div class="title_menu">Configuration</div>
            </li>
            <li>
                <div class="title_small">Statuses</div>
            </li>
            <li><a href=""><i class="fa fa-tasks" title="Assessment"></i><span>Assessment</span></a></li>
            <li><a href=""><i class="fa fa-envelope" title="Email Template"></i><span>Email Template</span></a></li>
            <li><a href=""><i class="fa fa-plus" title="Extension"></i><span>Extension</span></a></li>
            <li><a href=""><i class="fa fa-user" title="User"></i><span>User</span></a></li>
            <li>
                <div class="title_small">General</div>
            </li>
            <li><a href=""><i class="fa fa-star" title="Assessment Ratings"></i><span>Assessment Ratings</span></a></li>
            <li><a href=""><i class="fa fa-list-ol" title="Items per Domain"></i><span>Items per Domain</span></a></li>
            <li><a href=""><i class="fa fa-bell" title="Notification Preferences"></i><span>Notification
                        Preferences</span></a></li>
        </ul>
    </div> -->

    <div class="menu_block" *ngIf="globals.ProductId==globals.ProductIdArray[1]">
        <ul>
            <!-- <li>
                <div class="title_menu">Covid Dashbaord</div>
            </li> -->
            <!-- <li><a href="{{globals.covidUrl}}" target="_blank"><i class="fa fa-tasks"
                        title="My Assessments"></i><span>My Assessments</span></a></li> -->

            <li [ngClass]="{'active':(globals.currentLink.split('/')[2]=='dashboard')}"><a
                    routerLink="/covid/dashboard"><i class="fa fa-tachometer"
                        title="Admin Dashboard"></i><span>Dashboard</span></a></li>
            <!-- <li><a href="{{globals.covidUrl}}" target="_blank"><i class="fa fa-tasks"
                title="My Assessments"></i><span>My Assessments</span></a></li> -->
        </ul>
        <ul>
            <li>
                <div class="title_small">Reports</div>
            </li>
            <li [ngClass]="{'active':globals.currentLink.split('/')[3]=='subdomain-average'}">
                <a routerLink="/covid/report/subdomain-average"><i class="fa fa-bar-chart"
                        title="Domains"></i><span>Global Average</span></a>
            </li>
        </ul>
        <ul
            *ngIf="globals.permissions['domain-list'] || globals.permissions['subdomain-list'] || globals.permissions['question-list'] || globals.permissions['assessment-list'] || globals.permissions['user-list']">
            <!-- <li>
                <div class="title_menu">Manage</div>
            </li> -->

            <li
                *ngIf="globals.permissions['domain-list'] || globals.permissions['subdomain-list'] || globals.permissions['question-list']">
                <div class="title_small">General</div>
            </li>

            <li *ngIf="globals.permissions['domain-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='domain')}">
                <a routerLink="/covid/configuration/domain/list"><i class="fa fa-th"
                        title="Domains"></i><span>Domains</span></a>
            </li>
            <li *ngIf="globals.permissions['subdomain-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='subdomain')}">
                <a routerLink="/covid/configuration/subdomain/list"><i class="fa fa-th"
                        title="Subdomains"></i><span>Subdomains</span></a>
            </li>
            <li *ngIf="globals.permissions['question-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='item')}">
                <a routerLink="/covid/configuration/item/list"><i class="fa fa-list-ol"
                        title="Items"></i><span>Items</span></a>
            </li>

            <li *ngIf="globals.permissions['assessment-list'] || globals.permissions['user-list']">
                <div class="title_small">List</div>
            </li>
            <li *ngIf="globals.permissions['user-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && globals.currentLink.split('/')[3]=='user')}">
                <a routerLink="/covid/assessment/user/list"><i class="fa fa-users"
                        title="Users"></i><span>Users</span></a>
            </li>
            <li *ngIf="globals.permissions['assessment-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && globals.currentLink.split('/')[3]=='list')}">
                <a routerLink="/covid/assessment/list"><i class="fa fa-tasks"
                        title="Assessments"></i><span>Assessments</span></a>
            </li>
        </ul>
        <ul
            *ngIf="globals.permissions['configuration-list'] || globals.permissions['emailtemplate-list'] || globals.permissions['permission-list'] || globals.permissions['errorlog-list']">
            <!-- <li>
                <div class="title_menu">Configuration</div>
            </li> -->

            <!-- <li *ngIf="globals.permissions['configuration-list']">
                <div class="title_small">Status</div>
            </li>
            <li *ngIf="globals.permissions['configuration-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='assessment-status')}">
                <a routerLink="/covid/configuration/assessment-status"><i class="fa fa-tasks"
                        title="Assessment"></i><span>Assessment</span></a>
            </li> -->
            <li
                *ngIf="globals.permissions['configuration-list'] && globals.institute_details && globals.institute_details?.role_value == 1">
                <div class="title_small">Configuration</div>
            </li>

            <li *ngIf="globals.permissions['configuration-list'] && globals.institute_details && globals.institute_details?.role_value == 1"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='assessment-ratings')}">
                <a routerLink="/covid/configuration/assessment-ratings"><i class="fa fa-star"
                        title="Assessment Ratings"></i><span>Assessment Ratings</span></a>
            </li>

            <li *ngIf="globals.permissions['configuration-list'] && globals.institute_details && globals.institute_details?.role_value == 1"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='subdomain-wise-items')}">
                <a routerLink="/covid/configuration/subdomain-wise-items"><i class="fa fa-list-ol"
                        title="Items per Subdomain"></i><span>Items per Subdomain</span></a>
            </li>

            <li
                *ngIf="(globals.permissions['configuration-list'] && globals.institute_details && globals.institute_details?.role_value == 1) || globals.permissions['emailtemplate-list'] || globals.permissions['permission-list'] || globals.permissions['errorlog-list']">
                <div class="title_small">Super Administration Configuration</div>
            </li>

            <li *ngIf="globals.permissions['configuration-list'] && globals.institute_details && globals.institute_details?.role_value == 1"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='general')}">
                <a routerLink="/covid/configuration/general"><i class="fa fa-cogs"
                        title="General Settings"></i><span>General Settings</span></a>
            </li>

            <li *ngIf="globals.permissions['emailtemplate-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='email-template')}">
                <a routerLink="/covid/configuration/email-template/list"><i class="fa fa-envelope"
                        title="Email Template"></i><span>Email Template</span></a>
            </li>

            <li *ngIf="globals.permissions['permission-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='role-permission')}">
                <a routerLink="/covid/configuration/role-permission"><i class="fa fa-key"
                        title="Role Permission"></i><span>Role Permission</span></a>
            </li>

            <li *ngIf="globals.permissions['errorlog-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='error-log-reader')}">
                <a routerLink="/covid/configuration/error-log-reader"><i class="fa fa-bug"
                        title="Error Logs"></i><span>Error Logs</span></a>
            </li>
        </ul>
    </div>

    <div class="menu_block" *ngIf="globals.ProductId==globals.ProductIdArray[2]">
        <ul
            *ngIf="(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2 || globals.institute_details?.role_value == 3)">
            <!-- <li>
                <div class="title_menu">DCM Dashbaord</div>
            </li> -->
            <!-- <li><a href="{{globals.dcmUrl}}" target="_blank"><i class="fa fa-tasks" title="My Assessments"></i><span>My
                        Assessments</span></a></li> -->

            <!-- <li><a href=""><i class="fa fa-tachometer" title="Admin Dashboard"></i><span>Admin Dashboard</span></a></li> -->
            <li *ngIf="(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='admin-dashboard')}"><a
                    routerLink="/dcm/admin-dashboard"><i class="fa fa-tachometer" title="IFMA Dashboard"></i><span>IFMA
                        Dashboard</span></a></li>
            <li [ngClass]="{'active':(globals.currentLink.split('/')[2]=='organization-dashboard')}"><a
                    routerLink="/dcm/organization-dashboard"><i class="fa fa-tachometer"
                        title="Organization Dashboard"></i><span>
                        <span
                            *ngIf="(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)">
                            Organization Dashboard</span>
                        <span
                            *ngIf="!(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)">Dashboard</span>
                    </span></a></li>
            <!-- <li><a href="{{globals.dcmUrl}}" target="_blank"><i class="fa fa-tasks" title="My Assessments"></i><span>My
                Assessments</span></a></li> -->
        </ul>
        <ul
            *ngIf="globals.permissions['system-report'] || globals.permissions['organization-report'] || globals.permissions['division-report'] || globals.permissions['aggregation-report']">
            <li>
                <div class="title_small">Reports</div>
            </li>
            <li *ngIf="globals.permissions['system-report'] && globals.institute_details.role_value != 3"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='report-individuals')}">
                <a routerLink="/dcm/configuration/report-individuals"><i class="fa fa-table"
                        title="User Overview"></i><span>User Overview</span></a>
            </li>
            
           <!-- {{globals.permissions['organization-report'] ||  globals.institute_details.role_value == 3}} -->
            <li  *ngIf="globals.permissions['organization-report'] ||  globals.institute_details.role_value == 3"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='report-organizations')}">
                <a routerLink="/dcm/configuration/report-organizations"><i class="fa fa-table"
                        title="Organizations Overview"></i><span>Organizations Overview</span></a>
            </li>

            <li *ngIf="globals.permissions['division-report']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='report-per-organization')}">
                <a routerLink="/dcm/configuration/report-per-organization"
                    *ngIf="(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)"><i
                        class="fa fa-table" title="Divisions Overview"></i>
                    <span>Divisions Overview
                    </span>
                </a>
                <a routerLink="/dcm/configuration/report-per-organization"
                    *ngIf="!(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)"><i
                        class="fa fa-table" title="Divisions Overview"></i>
                    <span>Divisions
                        Overview</span>
                </a>
            </li>
            <li *ngIf="globals.permissions['system-report'] && globals.institute_details.role_value == 1"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='report-systems')}">
                <a routerLink="/dcm/configuration/report-systems"><i class="fa fa-table"
                        title="Systems Overview"></i><span>Systems Overview</span></a>
            </li>
            <li *ngIf="globals.permissions['aggregation-report'] && (globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='amsn-aggregation-report')}">
                <a routerLink="/dcm/amsn-aggregation-report"><i class="fa fa-table"
                        title="Aggregation Report"></i><span>Aggregation Report</span></a>
            </li>
            <li *ngIf="globals.permissions['aggregation-report']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='organization-aggregation-report')}">
                <a routerLink="/dcm/organization-aggregation-report"><i class="fa fa-table"
                        title="Gap Analysis Report"></i><span>Gap Analysis Report</span></a>
            </li>
        </ul>
       <!-- {{globals.institute_details.role_value}}
       {{(globals.permissions['domain-list'] || globals.permissions['subdomain-list'] || globals.permissions['question-list']) && globals.institute_details.role_value != 3}}
       {{globals.permissions['domain-list'] || globals.permissions['subdomain-list'] || globals.permissions['question-list']}} -->
        <ul
            *ngIf="(globals.permissions['domain-list'] || globals.permissions['subdomain-list'] || globals.permissions['question-list']) && globals.institute_details.role_value != 3">
            <li
                *ngIf="globals.permissions['domain-list'] || globals.permissions['subdomain-list'] || globals.permissions['question-list']">
                <div class="title_small">Manage</div>
            </li>

            <li *ngIf="globals.permissions['domain-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='domain')}">
                <a routerLink="/dcm/configuration/domain/list"><i class="fa fa-th"
                        title="Domains"></i><span>Domains</span></a>
            </li>
            <li *ngIf="globals.permissions['subdomain-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='subdomain')}">
                <a routerLink="/dcm/configuration/subdomain/list"><i class="fa fa-th"
                        title="Subdomains"></i><span>Subdomains</span></a>
            </li>
            <li *ngIf="globals.permissions['outcome-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='outcome')}">
                <a routerLink="/dcm/configuration/outcome/list"><i class="fa fa-th-list"
                        title="Outcome"></i><span>Outcomes</span></a>
            </li>
            <li *ngIf="globals.permissions['question-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='knowledge-statement')}">
                <a routerLink="/dcm/configuration/knowledge-statement/list"><i class="fa fa-list-ol"
                        title="Knowledge Statements"></i><span>Knowledge Statements</span></a>
            </li>



            <li *ngIf="globals.permissions['pt-domain-list'] || globals.permissions['pt-item-list']">
                <div class="title_small">Personality Assessment</div>
            </li>
            <li *ngIf="globals.permissions['pt-domain-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='personality-test-domain')}">
                <a routerLink="/dcm/configuration/personality-test-domain/list"><i class="fa fa-th"
                        title="Domains"></i><span>Domains</span></a>
            </li>
            <li *ngIf="globals.permissions['pt-item-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='personality-test-statement')}">
                <a routerLink="/dcm/configuration/personality-test-statement/list"><i class="fa fa-list-ol"
                        title="Statements"></i><span>Statements</span></a>
            </li>


            <!-- <li>
                <div class="title_small">User</div>
            </li>
            <li><a href=""><i class="fa fa-user" title="List"></i><span>List</span></a></li> -->
        </ul>

        <ul
            *ngIf="globals.permissions['add-assessment'] || globals.permissions['assessment-list'] || globals.permissions['extension-list'] || globals.permissions['license-list']">
            <!-- <li>
            <div class="title_menu">Manage</div>
        </li> -->

            <li *ngIf="globals.permissions['user-list']">
                <div class="title_small">List</div>
            </li>

        <li *ngIf="globals.permissions['license-list'] ||  globals.institute_details.role_value == 3"
            [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && globals.currentLink.split('/')[3]=='license-pack')}">
            <a routerLink="/dcm/assessment/license-pack/list"><i class="fa fa-list-alt"
                    title="Subscription Packs"></i><span>Subscription Packs</span></a>
        </li>
        <!-- <li *ngIf="globals.permissions['user-list']">
            <div class="title_small">User</div>
        </li> -->

        <li *ngIf="globals.permissions['user-list'] ||  globals.institute_details.role_value == 3"
            [ngClass]="{'active':(globals.currentLink.split('/')[3]=='user' && globals.currentLink.split('/')[4]=='list')}">
            <a routerLink="/dcm/assessment/user/list"><i class="fa fa-users"
                    title="Users"></i><span>Users</span></a>
        </li>
        <li
            *ngIf="globals.permissions['add-assessment'] || globals.permissions['assessment-list'] || globals.permissions['extension-list'] || globals.permissions['license-list']">
            <div class="title_small">Assessment</div>
        </li>

            <li *ngIf="globals.permissions['add-assessment']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && globals.currentLink.split('/')[3]=='invite')}">
                <a routerLink="/dcm/assessment/invite"><i class="fa fa-pencil-square-o"
                        title="Assessment Assign"></i><span>Assign</span></a>
            </li>

            <li *ngIf="globals.permissions['assessment-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && globals.currentLink.split('/')[3]=='list')}">
                <a routerLink="/dcm/assessment/list"><i class="fa fa-list"
                        title="Assessment List"></i><span>List</span></a>
            </li>

            <li
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && (globals.currentLink.split('/')[3]=='list-result' || globals.currentLink.split('/')[3]=='user-result'))}">
                <a routerLink="/dcm/assessment/list-result"><i class="fa fa-file-text-o"
                        title="Assessment Result"></i><span>Results</span></a>
            </li>

            <!-- <li *ngIf="globals.permissions['extension-list']"
            [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && globals.currentLink.split('/')[3]=='extension-request')}">
            <a routerLink="/dcm/assessment/extension-request/list"><i class="fa fa-calendar-plus-o"
                    title="Extension Requests"></i><span>Extension Requests</span></a>
        </li> -->

        </ul>

        <!-- <ul *ngIf="(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)">
            <li>
                <div class="title_small">Refund</div>
            </li>
            <li
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && (globals.currentLink.split('/')[3]=='refund-list'))}">
                <a routerLink="/dcm/assessment/refund-list"><i class="fa fa-list"
                        title="Refund Requests"></i><span>Requests</span></a>
            </li>
            <li
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && (globals.currentLink.split('/')[3]=='refund-history'))}">
                <a routerLink="/dcm/assessment/refund-history"><i class="fa fa-file-text-o"
                        title="Refund History"></i><span>History</span></a>
            </li>
        </ul> -->




        <!-- <ul>
            <li>
                <div class="title_small">Resources</div>
            </li>
            <li>
                <a routerLink=""><i class="fa fa-file" title="Resources"></i><span>List</span></a>
            </li>
        </ul> -->




        <ul *ngIf="(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)">
            <li>
                <div class="title_small">Refund</div>
            </li>
            <li
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && (globals.currentLink.split('/')[3]=='refund-list'))}">
                <a routerLink="/dcm/assessment/refund-list"><i class="fa fa-credit-card-alt"
                        title="Refund Requests"></i><span>Requests</span></a>
            </li>
            <li
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && (globals.currentLink.split('/')[3]=='refund-history'))}">
                <a routerLink="/dcm/assessment/refund-history"><i class="fa fa-list-alt"
                        title="Refund History"></i><span>History</span></a>
            </li>
        </ul>

    <!-- <ul *ngIf="(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)">
        <li>
        <div class="title_small">Refund</div>
    </li>
    <li 
    [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && (globals.currentLink.split('/')[3]=='refund-list'))}">
    <a routerLink="/dcm/assessment/refund-list"><i class="fa fa-credit-card-alt" title="Refund Requests"></i><span>Requests</span></a>
    </li>
    <li 
    [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && (globals.currentLink.split('/')[3]=='refund-history'))}">
    <a routerLink="/dcm/assessment/refund-history"><i class="fa fa-list-alt" title="Refund History"></i><span>History</span></a>
    </li>
    </ul> -->


    <ul *ngIf="(globals.institute_details?.role_value == 1 || globals.institute_details?.role_value == 2)">
        <li>
        <div class="title_small">Transactions</div>
    </li>
    <li 
    [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && (globals.currentLink.split('/')[3]=='transactions-history'))}">
    <a routerLink="/dcm/assessment/transactions-history"><i class="fa fa-credit-card-alt" title="Transactions History"></i><span>History</span></a>
    </li>
    <!-- <li 
    [ngClass]="{'active':(globals.currentLink.split('/')[2]=='assessment' && (globals.currentLink.split('/')[3]=='refund-history'))}">
    <a routerLink="/dcm/assessment/refund-history"><i class="fa fa-list-alt" title="Refund History"></i><span>History</span></a>
    </li> -->
    </ul>
    
        <ul
            *ngIf="globals.permissions['configuration-list'] || globals.permissions['emailtemplate-list'] || globals.permissions['permission-list'] || globals.permissions['errorlog-list']">
            <!-- <li>
                <div class="title_menu">Configuration</div>
            </li>
            <li *ngIf="globals.permissions['configuration-list']">
                <div class="title_small">Status</div>
            </li>
            <li *ngIf="globals.permissions['configuration-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='assessment-status')}">
                <a routerLink="/dcm/configuration/assessment-status"><i class="fa fa-tasks"
                        title="Assessment"></i><span>Assessment</span></a>
            </li>
            <li *ngIf="globals.permissions['configuration-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='personality-test-status')}">
                <a routerLink="/dcm/configuration/personality-test-status"><i class="fa fa-tasks"
                        title="Personality Assessment"></i><span>Personality Assessment</span></a>
            </li> -->
            <!-- <li *ngIf="globals.permissions['configuration-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='extension-status')}">
                <a routerLink="/dcm/configuration/extension-status"><i class="fa fa-calendar-plus-o"
                        title="Extension Status"></i><span>Extension</span></a>
            </li> -->
            <li *ngIf="globals.permissions['subdomain-prefrences-list']">
                <div class="title_small">Subdomain Preference</div>
            </li>
            <li *ngIf="globals.permissions['subdomain-prefrences-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='subdomain-preference' && !globals.currentLink.split('/')[4])}">
                <a routerLink="/dcm/configuration/subdomain-preference"><i class="fa fa-building"
                        title="Organization"></i><span>Organization</span></a>
            </li>
            <li *ngIf="globals.permissions['subdomain-prefrences-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='subdomain-preference' && (globals.currentLink.split('/')[4]=='individual' || globals.currentLink.split('/')[4]=='user'))}">
                <a routerLink="/dcm/configuration/subdomain-preference/individual"><i class="fa fa-user"
                        title="Individual User"></i><span>Individual User</span></a>
            </li>
            <li *ngIf="globals.permissions['configuration-list']">
                <div class="title_small">Configuration</div>
            </li>

            <li *ngIf="globals.permissions['configuration-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='assessment-ratings')}">
                <a routerLink="/dcm/configuration/assessment-ratings"><i class="fa fa-star"
                        title="Assessment Ratings"></i><span>Assessment Ratings</span></a>
            </li>
            <li *ngIf="globals.permissions['configuration-list'] && globals.institute_details && globals.institute_details?.role_value == 1"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='subdomain-wise-items')}">
                <a routerLink="/dcm/configuration/subdomain-wise-items"><i class="fa fa-list-ol"
                        title="Items per Subdomain"></i><span>Items per Subdomain</span></a>
            </li>

            <li
                *ngIf="(globals.permissions['configuration-list'] && globals.institute_details && globals.institute_details?.role_value == 1) || globals.permissions['emailtemplate-list'] || globals.permissions['permission-list'] || globals.permissions['errorlog-list']">
                <div class="title_small">Super Administration Configuration</div>
            </li>
            <li *ngIf="globals.permissions['configuration-list'] && globals.institute_details && globals.institute_details?.role_value == 1"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='general')}">
                <a routerLink="/dcm/configuration/general"><i class="fa fa-cogs"
                        title="General Settings"></i><span>General Settings</span></a>
            </li>

            <li *ngIf="globals.permissions['emailtemplate-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='email-template')}">
                <a routerLink="/dcm/configuration/email-template/list"><i class="fa fa-envelope"
                        title="Email Template"></i><span>Email Template</span></a>
            </li>

            <li *ngIf="globals.permissions['configuration-list'] && globals.institute_details && globals.institute_details?.role_value == 1"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='email-reminder')}">
                <a routerLink="/dcm/configuration/email-reminder"><i class="fa fa-bell"
                        title="Email Reminder Settings"></i><span>Email Reminder Settings</span></a>
            </li>

            <li *ngIf="globals.permissions['permission-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='role-permission')}">
                <a routerLink="/dcm/configuration/role-permission"><i class="fa fa-key"
                        title="Role Permission"></i><span>Role Permission</span></a>
            </li>

            <li *ngIf="globals.permissions['errorlog-list']"
                [ngClass]="{'active':(globals.currentLink.split('/')[2]=='configuration' && globals.currentLink.split('/')[3]=='error-log-reader')}">
                <a routerLink="/dcm/configuration/error-log-reader"><i class="fa fa-bug"
                        title="Error Logs"></i><span>Error Logs</span></a>
            </li>
        </ul>
    </div>

</div>