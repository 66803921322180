import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from "@angular/common";
import { SharedModule } from './app-shared/app-shared.module';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

// Import Routes
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { PermissionNotFoundComponent } from './permission-not-found/permission-not-found.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { IntermediatePageComponent } from './intermediate-page/intermediate-page.component';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { ItemListComponent } from './_common_components/item-list/item-list.component';
import { LoginComponent } from './login/login.component';

import { CommonAppComponent } from './_common_components/common-app/common-app.component';
import { DomainComponent } from './_common_components/domain/domain.component';
import { DomainListComponent } from './_common_components/domain-list/domain-list.component';
import { SubDomainComponent } from './_common_components/sub-domain/sub-domain.component';
import { SubDomainListComponent } from './_common_components/sub-domain-list/sub-domain-list.component';
import { ItemComponent } from './_common_components/item/item.component';
import { ItemPerSubdomainComponent } from './_common_components/item-per-subdomain/item-per-subdomain.component';

// Kendo-UI Elements
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { PagerModule } from '@progress/kendo-angular-pager';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AuthenticationFailedComponent } from './authentication-failed/authentication-failed.component';


@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SideMenuComponent,
        PermissionNotFoundComponent,
        NotFoundComponent,
        IntermediatePageComponent,
        LandingDashboardComponent,
        LoginComponent,
        CommonAppComponent,
        DomainComponent,
        DomainListComponent,
        SubDomainComponent,
        SubDomainListComponent,
        ItemComponent,
        ItemPerSubdomainComponent,
        BreadcrumbsComponent,
        AuthenticationFailedComponent,
        ItemListComponent
    ],
    exports: [SharedModule],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        // Kendo Elements
        ButtonsModule,
        InputsModule,
        TooltipModule,
        LabelModule,
        EditorModule,
        DropDownsModule,
        PagerModule,
        // AngularMultiSelectModule,
        ProgressBarModule,
        GridModule,
        PDFModule,
        ExcelModule,
        TreeViewModule,
        DateInputsModule,
        
        DialogsModule], providers: [Title, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
